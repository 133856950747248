.widget {
  margin-bottom: 0.5rem;
  padding: 1.7rem 0;

  &:last-child {
    margin-bottom: 0;
  }

  .widget-title {
    padding: 0 1.9rem;
    margin-bottom: 1.6rem;

    &.line {
      padding-bottom: 1rem;
      border-bottom: 2px solid $background-primary;
    }
  }

  &__body {
    padding: 0 1.9rem;
  }

  ul {
    @include reset-list();

    li {
      position: relative;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.widget-category {
  border-radius: 5px;
  border: 0.5px solid $color-four;
  background-color: $background-four;

  .widget-title {
    margin-bottom: 2rem;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      height: 100%;
      width: 4px;
      top: 0;
      left: 0;
    }
  }

  .list-group {
    @media (max-width: 991px) {
      display: flex;
      grid-gap: 24px;
      flex-direction: column;

      li {
        font-size: 1.5rem;

        &:not(:last-child) {
          margin-bottom: 0;
        }
      }

      &.list-group-radio {
        .form-check-label {
          &:before {
            top: 3px;
          }
        }

        .form-check-input[type='radio']:checked ~ label:after {
          top: 6px;
        }
      }
    }

    @media (min-width: 768px) and (max-width: 991px) {
      grid-template-columns: repeat(3, auto);
    }

    @media (max-width: 767px) {
      grid-template-columns: repeat(2, auto);
    }
  }
}

.widget-keywords {
  .widget-title {
    font-size: 2rem;
    font-weight: 400;
  }

  &__warp {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1rem 2rem;
  }

  .btn-keywords {
    padding: 0 1.1rem;
    display: block;
    line-height: 30px;
    border-radius: 3px;
    border: none;
    min-width: 75px;
    background-color: $background-second;
    @include transition();

    &:hover {
      color: $color-white;
      background-color: $background-primary;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 1.6rem;
    }
  }
}

.widget-favorite {
  &__link {
    padding: 1.6rem 2.1rem;
    display: grid;
    grid-column-gap: 2rem;
    grid-template-columns: auto 1fr;
    align-items: center;
    color: $color-four;
    border-radius: 5px;
    border: 2px solid $color-four;

    span {
      &.text {
        font-size: 1.8rem;
        font-weight: 700;
      }
    }

    &:hover {
      color: $color-white;
      background-color: $color-four;
    }
  }
}
@media only screen and (max-width: 660px) and (min-width: 400px) {
  .widget-category {
    display: flex;
    width: 300px;
    flex-direction: column;
    .form-check-label__cate {
      display: -webkit-box;
      line-height: 16px;
      max-height: 15px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      visibility: visible;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 70px;
    }
  }
}
