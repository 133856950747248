.header-global {
    padding: 1.8rem 0;
    background-color: $color-white;
    box-shadow: 0 3px 5px #0000001F;

    &__nav {
        height: inherit;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            max-width: 45%;
            img {
                width: 399.72px;
                height: 67.98px;
            }
        }

        .logo-left {
            max-width: 45%;
            img {
                width: 431.75px;
                height: 79.98px;
            }
        }

        @media (min-width: 768px) and (max-width: 991px) {
            .logo {
                img {
                    max-height: 65px;
                    width: auto;
                    height: unset;
                }
            }

            .logo-left {
                img {
                    max-height: 58px;
                    width: auto;
                    height: unset;
                }
            }
        }

        @media (max-width: 767px) {
            .logo {
                img {
                    max-height: 52px;
                    width: auto;
                    height: unset;
                }
            }

            .logo-left {
                img {
                    max-height: 42px;
                    width: auto;
                    height: unset;
                }
            }
        }
    }

    .info {
        font-size: 1.6rem;
        color: $color-third;

        .mail {}

        @media (max-width: 479px) {
            font-size: 1.3rem;
        }
    }
}
