* {
  outline: none !important;
}

html {
  min-height: 100%;
  font-size: 62.5%;
}

body {
  min-height: 100vh;
  overflow-x: hidden;
  word-break: break-word;
  height: 100%;
  @include flex-column;
}

.sticky-footer {
  flex: 1 0 auto;
  overflow-x: hidden;
}

a {
  @include transition();
}

p {
  &:last-child {
    margin-bottom: 0;
  }
}
.top__right{
  color:#707070;
}
figure {
  margin: 0;
}

iframe {
  max-width: 100%;
}

img {
  max-width: 100%;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  border-color: transparent;
}

.site-page {
  padding-top: 2.5rem;
  padding-bottom: 6rem;
}

.warp-container {
  margin: 0 auto;
  max-width: 810px;
}

.breadcrumb-option {
  margin-bottom: 3rem;
}

.breadcrumb {
  font-size: $font-size-second;
  
  a {
    display: inline-block;
    color: $color-third;
  }
  
  .breadcrumb-item {
    &.active {
      color: $color-third;
    }
    ~ .breadcrumb-item:before {
      content: "\e5cc";
      font-family: $font-icons;
    }
  }
}

.btn-icon {
  padding: 0;
  color: $color-second;
  border: none;
  background-color: transparent;
}

.form-select {
  border: 1px solid $border-color;
  border-radius: 3px;
}

.custom-form-check {
  position: relative;
  
  .form-check-label {
    position: relative;
    padding-left: 2.8rem;
    font-size: 1.5rem;
    
    &:before {
      position: absolute;
      content: "\e835";
      bottom: 2px;
      left: 0;
      font-family: $font-icons;
      font-size: 2.3rem;
      color: #aaa;
      line-height: 18px;
      @include box(18px);
    }
  }
  
  .form-check-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    
    &:checked {
      ~ .form-check-label {
          &:before {
            content: "\e834";
            color: $color-second;
          }
      }
    }
  }
}

.under-line {
  margin-bottom: 1.2rem;
  padding-bottom: 0.6rem;
  border-bottom: 1px solid $background-primary;
}

.reset-list {
  @include reset-list();
}

.list-group-radio {
  .form-check-input {
    &[type="radio"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      width: 0;
      height: 0;
      
      &:checked {
        ~ label {
          &:after {
            top: 6px;
            left: 3px;
            @include square(14px, 50%);
            background: $background-hover;
          }
        }
      }
    }
  }
  
  .form-check-label {
    padding-left: 3rem;
    position: relative;
    
    &:before {
      top: 3px;
      left: 0;
      @include square(20px, 50%);
      background: #fff;
      border: 1px solid $background-hover;
    }
    
    &:before,
    &:after {
      position: absolute;
      content: '';
      cursor: pointer;
    }
  }
}

.box-shadow {
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 3px 3px #0000001A;
}

.back-top {
  position: fixed;
  bottom: 6rem;
  color: $color-third;
  opacity: 0;
  visibility: hidden;
  border: 1px solid $color-third;
  z-index: 2;
 
  @include transition();
  
  &.active:hover,
  &:hover {
    color: #fbaed2;
    border-color: #fbaed2;
    background-color: #fff;
  }
  
  &.active {
    opacity: 1;
    visibility: visible;
    bottom: 8.5rem;
    color: $color-third;
    background-color: #F2F2F2;
    border: 1px solid $color-third;
  }
  
  @media (min-width: 1400px) {
    padding: 1rem 2.8rem;
    right: 7.5rem;
    border-radius: 5px;
    background-color: #F5F6FA;
  }
  
  @media (max-width: 1399px) {
    padding: 0;
    right: 1.2rem;
    line-height: 35px;
    @include square(35px, 50%);
    justify-content: center;
    
    .text {
      display: none;
    }
    
    &.active {
      bottom: 8rem;
    }
  }
}

.navigation {
  .pagination {
    margin: 0;
    grid-gap: 12px 5px;
    
    .page-item {
      &__link {
        display: block;
        padding: 0;
        text-align: center;
        line-height: 30px;
        color: $color-primary;
        font-size: $font-size-second;
        @include box(30px);
        border-radius: 3px;
        border: 1px solid #aaa;
        min-width: max-content;
      }
  
      span {
        &:not(.material-icons):not(.dot) {
          color: $color-white;
          border-color: $background-primary;
          background-color: $background-primary;
        }
      }
      
      a {
        &:hover,
        &.active {
          color: $color-white;
          border-color: $background-primary;
          background-color: $background-primary;
        }
      }
    }
  }
}

.error {
  color: #ff5555;
}

.alert {
  margin-top: 1.5rem;
  background-color: $color-four;
  padding: 0.5rem 3rem 0.5rem 1rem;
  position: relative;
  opacity: 0;
  p {
		color: $color-white;
		font-size: 1.6rem;
  }

  .btn-close-alert {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0.5rem;
    margin: auto;
    color: $color-white;
  }
}

.show-alert {
  animation: fadeOut 5s linear forwards;
}

.notransition {
  animation: unset !important;
  opacity: 0 !important;
}

#loader {
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: absolute;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
  margin: auto;
}

.loader{
  position: absolute;
  z-index: 50;
  pointer-events: none;
  background-color: #000;
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}

.text-right {
  text-align: right;
}

.title-company {
  color: red;
  text-transform: uppercase;
  font-style: italic;
  font-weight: bold;
  font-size: 2.4rem;
  font-family: fantasy;

  &:hover {
    color: red;
  }
}

.font-weight-bold {
  font-weight: bold;
}

.tox.tox-tinymce:focus, .site-admin input:focus, .site-admin select:focus, .form-control:focus, .form-select:focus  {
  border-color: $color-hover!important;
  box-shadow: unset;
}

// .filter .control-group input:focus {
//    border-color: $color-hover;
// }

.dropdown-select-time {
  input {
    padding-right: 40px;
  }
  .dropdown-menu {
    .select-time {
      display: flex;
      .prev, .next {
        padding: 13px;
        cursor: pointer;
        font-weight: bold;
        &:hover {
          background-color: var(--bs-dropdown-link-hover-bg);
        }
      }
      .select-hour, .select-minute {
        padding: 20px 30px;
      }
      .select-options {
        height: 96px;
        overflow-y: scroll;
        overflow-x: hidden;
        margin: 15px 0;
        .dropdown-item {
          color: gray;
          scroll-snap-stop: always;
          cursor: pointer;
          &.active {
              color: #000;
              background-color: transparent;
          } 
  
          &:active {
              color: #000;
              background-color: transparent;
          }
        }
        &::-webkit-scrollbar {
            display: none;
        }
      }
    }
  }
  .dropdown-select-time-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
}

.disabled {
  .dropdown-select-time {
    pointer-events: none;
  }
  .icon-published-at {
    background-color: #707070 !important;
  }
}
