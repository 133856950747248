// Color
$color-primary: #000000;
$color-title: #000000;
$color-second: #fbaed2;
$color-third: #707070;
$color-four: #fbaed2;
$color-link: #005DFF;
$color-hover: #fbaed2;
$color-required: #fbaed2;
$color-white: #ffffff;
$color-cancel-hover: #D72619;
$color-submit-hover: #fbaed2;
// Background
$background-body: #ffffff;
$background-footer: #000000;
$background-primary: #fbaed2;
$background-second: #fff8f8;
$background-third: #F5F6FA;
$background-four: #F2F2F2;
$background-five: #fff8f8;
$background-hover: #fbaed2;
$background-toolbar-tinimce: #E5E5E5;
$background-menu-bar: #f0f0f0;
$background-menu-bar-hover: #fbaed2;
// Border color
$border-color: #aaaaaa;
$border-color-second: #e9e9e9;
$border-color-third: #e0e0e0;
$border-color-four: #aaaaaa;