@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin square($size, $radius: 0) {
  width: $size;
  height: $size;
  
  @if $radius != 0 {
    border-radius: $radius;
  }
}

@mixin cover-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-center-column {
  @include flex-center;
  flex-direction: column;
}

@mixin flex-center-vert {
  display: flex;
  align-items: center;
}

@mixin flex-center-horiz {
  display: flex;
  justify-content: center;
}

@mixin transition( $transition: all 0.4s ease-in-out ) {
  transition: $transition;
}

@mixin grid-columns($gap, $columns) {
  display: grid;
  grid-gap: $gap;
  grid-template-columns: $columns;
}