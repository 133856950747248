.option-change-font-size {
  .text {
	font-size: $font-size-second;
  }
  
  .action-group {
	.btn {
	  display: inline-block;
	  padding: 0.4rem 0.9rem;
	  border-radius: 3px;
	  box-shadow: none;
	  border: none;
	  background-color: $background-second;
	  @include transition();
	  
	  ~ .btn {
		margin-left: 0.5rem;
	  }
	  
	  &.active,
	  &:hover {
		color: $color-white;
		background-color: $background-primary;
	  }
	}
  }
}